import { ApiResponse, ITeamInfo, ITeamInfoObj, IUserInfo, User } from "../interface";
import { Get, Post, Put } from "../server";

export function getUserInfo<T = IUserInfo>(): ApiResponse<T> {
  return Get<T>("/users/me");
}

type ITeamInfoArrayObj = { teams: ITeamInfo[] };
export function getTeamsInfo(): ApiResponse<ITeamInfoObj[]> {
  return new Promise((resolve, reject) => {
    Get<ITeamInfoArrayObj>("/teams/teams")
      .then((response) => {
        let teamInfoList = new Array<ITeamInfoObj>();
        if (response?.teams && Array.isArray(response.teams)) {
          teamInfoList = response.teams.map((team: ITeamInfo) => {
            return {
              teamId: team.team_id,
              displayName: team.display_name,
              personal: team.personal,
              iconUrl: team.icon_url,
              role: team.role,
              defaultPolicy: team.default_policy,
              domain: team.domain,
              memberNum: team.total,
            };
          });
        }
        resolve(teamInfoList);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTeamMemberInfo<T = { users: User[] }>(teamId: string): ApiResponse<T> {
  return Get<T>(`teams/team/${teamId}/users`);
}

export function updateWorkspaceName<T = { team_id: string }>(teamName: string): ApiResponse<T> {
  return Post<T>("/teams/team", {
    display_name: teamName,
  });
}

export function joinTeamWithId<T = { role: string; team_id: string; user_id: string }>(
  teamId: string,
  code: string
): ApiResponse<T> {
  return Put<T>(`teams/team/${teamId}/join`, { code });
}

const userApi = {
  getUserInfo,
  getTeamsInfo,
  getTeamMemberInfo,
  updateWorkspaceName,
  joinTeamWithId,
};
export default userApi;
