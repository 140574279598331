import GoogleIcon from "./icons/google.png";
import MicrsoftIcon from "./icons/microsoft.png";
import SlackIcon from "./icons/slack.png";
import AppleIcon from "./icons/apple.png";

export const SSOLinks = [
  {
    name: "google",
    icon: GoogleIcon,
    defaultText: "Google",
  },
  // {
  //   name: "ethereum",
  //   icon: EthereumIcon,
  // },
  {
    name: "microsoft",
    icon: MicrsoftIcon,
    defaultText: "Micro",
  },
  {
    name: "slack",
    icon: SlackIcon,
    defaultText: "Slack",
  },
  {
    name: "apple",
    icon: AppleIcon,
    defaultText: "Apple",
  },
];
