const EMAIL_REGEX_PATTERN =
  /^(?=.{1,255}$)(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEX_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
const USERNAME_REGEX_PATTERN = /^.{3,30}$/;
const WORKSPACE_NAME_REGEX_PATTERN = /^.{3,100}$/;

const passwordInvalidContent = [
  Object.freeze({
    pattern: /^.{8,30}$/,
    content: "Must contain at least 8 characters",
  }),
  Object.freeze({
    pattern: /^(?=.*[A-Za-z])(?=.*\d).*$/,
    content: "Must use both letters and numbers",
  }),
  Object.freeze({
    pattern: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
    content: "Must contain both lowercase and uppercase letters",
  }),
];

const emailProps = Object.freeze({
  inputType: "text",
  pattern: EMAIL_REGEX_PATTERN,
  placeholder: "Email",
  invalidContent: "Incorrect email format",
  maxLength: 255,
});
const passwordCreateProps = Object.freeze({
  inputType: "password",
  pattern: PASSWORD_REGEX_PATTERN,
  placeholder: "Password",
  invalidContent: passwordInvalidContent,
  maxLength: 255,
});
const passwordCheckProps = Object.freeze({
  inputType: "password",
  pattern: PASSWORD_REGEX_PATTERN,
  placeholder: "Password",
  invalidContent: "Incorrect password",
  maxLength: 255,
});
const usernameProps = Object.freeze({
  inputType: "text",
  pattern: USERNAME_REGEX_PATTERN,
  placeholder: "Name",
  invalidContent: "Incorrect username format",
  maxLength: 30,
});
const workspaceNameProps = Object.freeze({
  inputType: "text",
  pattern: WORKSPACE_NAME_REGEX_PATTERN,
  placeholder: "Your's workspace name",
  invalidContent: "Incorrect workspace format",
  maxLength: 100,
});
export const inputProps = Object.freeze({
  email: emailProps,
  passwordCreate: passwordCreateProps,
  passwordCheck: passwordCheckProps,
  username: usernameProps,
  workspaceName: workspaceNameProps,
});
