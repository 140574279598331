import React, { useCallback, useEffect, useState } from "react";
import CustomLayout from "@/components/CustomLayout";
import message from "@/components/CustomMessage";
import VibeTitle from "@/components/VibeTitle";

import { useAuth } from "@/hooks/authHooks";
import api from "@/api";
import Log from "@/utils/Log";
import constants from "@/constants";

import "./styles.css";

const CHECK_DELAY = 2000;

const LOGIN_STATUS = {
  START: "login_status_start",
  PENDING: "login_status_pending",
  DONE: "login_status_done",
  FAILED: "login_status_failed",
};

const subtitle = (email: string) => (
  <>
    We have sent an email to <em className="em-text">{email}.</em>
    <br />
    This page will automatically refresh once your email is confirmed.
  </>
);

const EmailConfirm = () => {
  const [loginState, setLoginState] = useState(LOGIN_STATUS.START);
  const auth = useAuth();
  const email = auth?.email || sessionStorage.getItem(constants.EMAIL_KEY) || "";
  const checkCode =
    auth?.authState?.checkCode || sessionStorage.getItem(constants.CHECK_CODE) || "";

  const checkState = useCallback(
    (checkCode: string) => {
      api
        .checkEmailLoginStatus(checkCode)
        .then((res) => {
          if (!res || !res?.access_token) {
            Log.debug("login result failed");
            setLoginState(LOGIN_STATUS.FAILED);
          } else {
            setLoginState(LOGIN_STATUS.DONE);
            sessionStorage.removeItem(constants.EMAIL_KEY);
            sessionStorage.removeItem(constants.CHECK_CODE);
            auth.authUser(res?.access_token);
          }
        })
        .catch((err) => {
          Log.error("checkEmailLoginStatus err: ", err);
          message.error("Check email login status failed! Please resend email");
        });
    },
    [loginState]
  );

  const onTimeout = useCallback(() => {
    setLoginState(LOGIN_STATUS.START);
  }, []);

  useEffect(() => {
    if (!email) {
      Log.error("email not exist");
    }
  }, [email]);

  useEffect(() => {
    if (!checkCode) {
      Log.error("checkCode not exist: ", checkCode);
    } else if (loginState === LOGIN_STATUS.START) {
      checkState(checkCode);
    }
  }, [loginState, checkCode]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (loginState === LOGIN_STATUS.FAILED) {
      timer = setTimeout(onTimeout, CHECK_DELAY);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [loginState, onTimeout]);

  const hanldeResendEmail = () => {
    const isResend = true;
    auth.signInWithEmail(email, isResend).then((isSent) => {
      if (isSent) {
        message.success("The email is on the way. Please check your inbox.");
      } else {
        message.error("Resend failed!");
      }
    });
  };

  return (
    <CustomLayout contentClassName="max-width-500 flex-wrapper">
      <VibeTitle title="Please check your email" subtitle={subtitle(email)} />
      <p className="resend-text">
        Didn&apos;t receive an email?&nbsp;
        <span className="link-text title" onClick={hanldeResendEmail}>
          Resend.
        </span>
      </p>
    </CustomLayout>
  );
};

export default EmailConfirm;
