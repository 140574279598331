import React from "react";

import { InvalidHintProps } from "../../interface";
import "./styles.css";

const HintIcon = ({ isInvalid }: { isInvalid: boolean }) => {
  return isInvalid ? (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="2" fill="#FC4747" />
    </svg>
  ) : (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 5.875L5.125 8.425L9.375 3.75"
        stroke="#30D158"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const InvalidHint = (hint: InvalidHintProps) => {
  const { content, isInvalid, visibleValid } = hint;
  if (isInvalid || visibleValid) {
    return (
      <p className={"invalid-hint-wrapper " + (!isInvalid && "is-valid")}>
        {visibleValid && <HintIcon isInvalid={isInvalid} />}
        <span>{content}</span>
      </p>
    );
  }
  return null;
};

export default InvalidHint;
