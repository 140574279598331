import React, { useEffect, useRef, useState } from "react";

import CustomLayout from "@/components/CustomLayout";
import VibeTitle from "@/components/VibeTitle";
import Input from "@/components/Input";
import { useAuth } from "@/hooks";
import Log from "@/utils/Log";

const CreateWorkspace = () => {
  const [teamId, setTeamId] = useState<string>("");
  const [isValid, setValid] = useState<boolean>(false);
  const [showInvalid, setShowInvalid] = useState<boolean>(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const auth = useAuth();

  const placeholder = `${auth.authState.username || "Your"}'s workspace`;

  function handleValidationChange(isValid: boolean) {
    setValid(isValid);
  }

  function handleCreateWorkspace() {
    setShowInvalid(true);
    if (isValid) {
      const name = nameInputRef?.current?.value || "";
      auth.createWorkspace(name).then((teamId) => {
        Log.debug("create workspace success, team id is", teamId);
        setTeamId(teamId);
      });
    } else {
      nameInputRef.current?.focus();
    }
  }

  function handleSuccessRedirect() {
    auth.redirectToAppWithAuthParams({ teamId });
    Log.debug("redirectToAppWithAuthParams: ", teamId);
  }

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);
  return (
    <CustomLayout>
      {!teamId ? (
        <>
          <VibeTitle title="Create a workspace" subtitle="Name your workspace." />
          <Input
            className="workspace-input"
            type="workspaceName"
            prefilled={placeholder}
            inputRef={nameInputRef}
            placeholder={placeholder}
            realTimeInvalidStyle={showInvalid}
            onKeyDown={handleCreateWorkspace}
            onValidationChange={handleValidationChange}
            noPrefixIcon={true}
          />
          <button className="button-primary-360" onClick={handleCreateWorkspace}>
            Create
          </button>
        </>
      ) : (
        <>
          <VibeTitle
            title="Workspace created successfully"
            subtitle="You can now sign in to your Vibe account."
          />
          <button className="button-primary-360" onClick={handleSuccessRedirect}>
            Continue
          </button>
        </>
      )}
    </CustomLayout>
  );
};

export default CreateWorkspace;
