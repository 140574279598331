import React, { ReactNode } from "react";

import CustomImg from "../CustomImg";
import { redirectParams } from "@/utils";
import { currentServerUrl } from "@/api";

import { SSOLinks } from "./constants";

import "./styles.css";

const SSOLoginBtns = (children?: ReactNode) => {
  const authQuery = () => {
    const extraParams = new URLSearchParams();
    const query = new URLSearchParams(window.location.search);
    const paramsToBringFromQuery = [
      "folder_invitation_code", // For user clicked a folder invitation code then login
      "request_id", // For server to pair TV register request and login
    ];
    for (const param of paramsToBringFromQuery) {
      const q = query.get(param);
      if (q) {
        extraParams.append(param, q);
      }
    }

    // Referer header now does not include full path due to security enforcement.
    // We need to append from parameter for OAuth redirects. "from" must be an absolute URL for OAuth.
    const ssoFromUrl = `${window.location.origin}/?${redirectParams().toString()}`;
    extraParams.append("from", ssoFromUrl);
    return extraParams.toString();
  };
  const redirectHref = (name: string) =>
    `${currentServerUrl.restBaseUrl}/oauth/r/${name}?${authQuery() || ""}`;

  return (
    <div className="sso-wrapper">
      <p className="sso-desc">Or sign in with other accounts</p>
      <div className="icons-wrapper">
        {SSOLinks.map(({ name, icon, defaultText }) => (
          <a className="icon-link" key={name} href={redirectHref(name)}>
            <CustomImg defaultImg={defaultText} alt={name} src={icon} />
          </a>
        ))}
      </div>
      {children}
    </div>
  );
};

export default SSOLoginBtns;
