import React from "react";

const ArrowIcon = () => (
  <svg
    className="arrow-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7659 15.4343C20.0783 15.7467 20.0783 16.2533 19.7659 16.5657L14.6747 21.6569L13.5433 20.5255L18.0688 16L13.5433 11.4745L14.6747 10.3431L19.7659 15.4343Z"
      fill="#222222"
    />
  </svg>
);

export default ArrowIcon;
