import React from "react";
import { VibeFullLogo } from "@/assets/images";
import CustomLayout from "../CustomLayout";
import UnknownErrorLogo from "./UnknownErrorLogo";
import VibeTitle from "../VibeTitle";

import "./styles.css";

const UnknownError = ({
  title = "This page is not available.",
  subtitle,
}: {
  title?: string;
  subtitle?: string;
}) => {
  const unknownErrorHeader = (
    <figure className="unknown-error vibe-icon">
      <VibeFullLogo />
    </figure>
  );
  return (
    <CustomLayout header={unknownErrorHeader}>
      <VibeTitle
        className="unknown-error"
        icon={UnknownErrorLogo}
        title={title}
        subtitle={subtitle}
      />
    </CustomLayout>
  );
};

export default UnknownError;
