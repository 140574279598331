import React, { useEffect } from "react";

import Loading from "@/components/Loading";
import { useAuth } from "@/hooks";

const Logout = () => {
  const auth = useAuth();
  useEffect(() => {
    auth.logout();
  }, []);

  return <Loading />;
};

export default Logout;
