import React, { useEffect, useRef, useState } from "react";

import CustomLayout from "@/components/CustomLayout";
import Link from "@/components/CustomLink";
import SSOLoginBtns from "@/components/SSOLoginBtns";
import VibeTitle from "@/components/VibeTitle";
import Input from "@/components/Input";

import { useAuth } from "@/hooks/authHooks";
import { routes } from "@/constants";

const LoginLink = () => (
  <div className="bottom-link-wrapper">
    <span className="title">Already have a Vibe account?</span>
    <Link to={routes.login}>Sign In</Link>
  </div>
);

const EmailRegister = () => {
  const [isValid, setValid] = useState<boolean>(false);
  const [showInvalid, setShowInvalid] = useState<boolean>(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const auth = useAuth();

  function handleValidationChange(isValid: boolean) {
    setValid(isValid);
  }

  function handleRegisterClick() {
    setShowInvalid(true);
    if (isValid) {
      const email = emailInputRef?.current?.value || "";
      auth.signInWithEmail(email);
    } else {
      emailInputRef.current?.focus();
    }
  }

  useEffect(() => {
    emailInputRef.current?.focus();
  }, []);

  return (
    <div className="main-login register-wrapper">
      <VibeTitle title="Create a Vibe account" />
      <Input
        realTimeInvalidStyle={showInvalid}
        type="email"
        inputRef={emailInputRef}
        onKeyDown={handleRegisterClick}
        onValidationChange={handleValidationChange}
      />
      <button className="button-primary-360" onClick={handleRegisterClick}>
        Continue
      </button>
      <LoginLink />
    </div>
  );
};

const policyLinks = (
  <p className="policy-para">
    By creating an account, you agree to the&nbsp;
    <a className="policy-para-link" href="https://vibe.us/terms-of-service/">
      Vibe Terms of service
    </a>
    &nbsp;and&nbsp;
    <a className="policy-para-link" href="https://vibe.us/privacy/">
      Privacy policy
    </a>
    .
  </p>
);

const Register = () => {
  const auth = useAuth();
  useEffect(() => {
    auth.authUser();
  }, []);

  return (
    <CustomLayout
      contentClassName="flex-space-between register-page"
      footer={SSOLoginBtns(policyLinks)}
    >
      <EmailRegister />
    </CustomLayout>
  );
};

export default Register;
