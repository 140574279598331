import React, { useEffect, useState } from "react";
import { InvalidHintType, InputPropTypes } from "./interface";
import InvalidHint from "./components/InvalidHint";
import InputPrefixIcon from "./components/InputPrefixIcon";

import { inputProps } from "./configs";
import "./styles.css";

const invalidHintContent = (
  invalidContent: string | InvalidHintType[],
  isInvalid: boolean,
  maxLength: number,
  value: string
) => {
  if (!value) {
    return <InvalidHint isInvalid={isInvalid} content={"Field has an invalid pattern"} />;
  }
  if (value.length > maxLength) {
    return <InvalidHint isInvalid={true} content={`Character limit (${maxLength}) reached`} />;
  }
  if (Array.isArray(invalidContent)) {
    return invalidContent.map((hint) => (
      <InvalidHint
        key={hint.content}
        isInvalid={!hint.pattern.test(value)}
        content={hint.content}
        visibleValid
      />
    ));
  } else {
    return <InvalidHint isInvalid={isInvalid} content={invalidContent} />;
  }
};

const Input = (props: InputPropTypes) => {
  const {
    type = "email",
    className,
    inputRef,
    prefilled,
    onValidationChange,
    onKeyDown,
    realTimeInvalidStyle = false,
    noPrefixIcon = false,
    ...restProps
  } = props;
  const [value, setValue] = useState(prefilled || "");
  const [isInvalid, setIsInvalid] = useState(false);

  const { inputType, pattern, placeholder, invalidContent, maxLength } = inputProps[type];

  const handleInputChange: InputChangeHandleType = (e) => {
    const value = e?.target?.value;
    setValue(value);
  };

  const enterKeyType = "Enter";
  const handleKeyDown: InputKeyDownHandleType = (e) => {
    if (onKeyDown && e.key === enterKeyType) {
      onKeyDown(e);
    }
  };

  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(!isInvalid);
    }
  }, [isInvalid]);

  useEffect(() => {
    if (!value) {
      setIsInvalid(!value);
      return;
    }
    const isValid = pattern.test(value);
    setIsInvalid(!isValid);
  }, [realTimeInvalidStyle, setIsInvalid, value]);

  const showInvalid = realTimeInvalidStyle && isInvalid;
  return (
    <div className={`input-component ${showInvalid && "is-invalid"} ${className}`}>
      <div className={"input-wrapper"}>
        {!noPrefixIcon && <InputPrefixIcon type={type} isInvalid={showInvalid} />}
        <input
          value={value}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          type={inputType}
          placeholder={placeholder}
          onChange={handleInputChange}
          {...restProps}
        />
      </div>
      {showInvalid && (
        <div className="hint-container">
          {invalidHintContent(invalidContent, isInvalid, maxLength, value)}
        </div>
      )}
    </div>
  );
};

export default Input;
