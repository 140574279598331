import React, { ReactNode } from "react";
import { useAuth } from "@/hooks";
import constants from "@/constants";
import { VibeCanvasLogo, VibeAdminLogo, VibeFullLogo, VibeOneLogo } from "@/assets/images";

type AppIcons = {
  [key in SignInApps]: ReactNode;
};

const appIcons: AppIcons = {
  admin: <VibeAdminLogo />,
  one: <VibeOneLogo />,
  canvas: <VibeCanvasLogo />,
  website: <VibeFullLogo />,
};

const Loading = () => {
  const auth = useAuth();
  const appLoadingLogo = auth.signInFrom || constants.DEFAULT_SIGN_IN_APP;

  return <div className="loading-wrapper">{appIcons[appLoadingLogo]}</div>;
};

export default Loading;
