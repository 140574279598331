import React from "react";

const WarningIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20Z"
      fill="#FCB447"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 15V13L11 13L11 15H9ZM9 12L9 5H11L11 12H9Z"
      fill="white"
    />
  </svg>
);

const SuccessIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#30D158"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9126 6.93275L9.57326 13.906C9.37869 14.1201 9.10097 14.2393 8.81179 14.2331C8.5226 14.2269 8.25028 14.0957 8.0651 13.8735L4.59158 9.70531L6.12803 8.42494L8.86612 11.7107L14.4327 5.5874L15.9126 6.93275Z"
      fill="#F1F1F1"
    />
  </svg>
);

const ErrorIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#FC4747"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00001 5.58582L10 8.58585L13 5.58582L14.4142 7.00002L11.4142 10.0001L14.4141 13L12.9999 14.4142L10 11.4143L7.0001 14.4142L5.58587 13L8.5858 10.0001L5.58578 7.00002L7.00001 5.58582Z"
      fill="#F1F1F1"
    />
  </svg>
);

export { WarningIcon, SuccessIcon, ErrorIcon };
