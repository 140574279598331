import React from "react";
import { VibeFullLogo } from "@/assets/images";
import Icon404 from "./Icon404";
import "./styles.css";

const NotFoundPage = () => {
  return (
    <div className="main-wrapper no-padding-top not-find-wrapper">
      <figure className="vibe-icon">
        <VibeFullLogo />
      </figure>
      <div className="margin-top-144">
        <Icon404 />
        <p className="wrong-content">Oops! Something went wrong...</p>
      </div>
      <div></div>
    </div>
  );
};

export default NotFoundPage;
