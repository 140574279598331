import React, { useEffect, useRef, useState } from "react";
import message from "@/components/CustomMessage";

import VibeTitle from "@/components/VibeTitle";
import ApprovalInfoList from "@/components/ApprovalInfoList";
import Input from "@/components/Input";
import UnknownError from "@/components/UnknownError";
import { useAuth } from "@/hooks";
import { getQueryValueByKeys } from "@/utils";
import Log from "@/utils/Log";

import constants from "@/constants";
import CustomLayout from "@/components/CustomLayout";

const RegisterState = {
  SUBMIT_USERNAME: "submit_username",
  APPROVED: "approved",
  UNDER_APPROVED: "under_approved",
  EXPIRED: "expired",
};

const SubmitUsername = ({ onUsernameSubmit }: { onUsernameSubmit: (username: string) => void }) => {
  const [isValid, setValid] = useState<boolean>(false);
  const [showInvalid, setShowInvalid] = useState<boolean>(false);
  const nameInputRef = useRef<HTMLInputElement>(null);

  function handleValidationChange(isValid: boolean) {
    setValid(isValid);
  }

  function handleSubmitClick() {
    setShowInvalid(true);
    if (isValid) {
      const username = nameInputRef?.current?.value || "";
      onUsernameSubmit(username);
    } else {
      nameInputRef?.current?.focus();
    }
  }

  useEffect(() => {
    nameInputRef?.current?.focus();
  }, []);

  return (
    <CustomLayout contentClassName="register-wrapper">
      <VibeTitle
        title="Set your display name"
        subtitle="Specify your display name for your Vibe account."
      />
      <Input
        realTimeInvalidStyle={showInvalid}
        type="username"
        inputRef={nameInputRef}
        onKeyDown={handleSubmitClick}
        onValidationChange={handleValidationChange}
      />
      <button className="button-primary-360" onClick={handleSubmitClick}>
        Continue
      </button>
    </CustomLayout>
  );
};

const UnderApprovalContent = ({
  username,
  onApproval,
}: {
  username: string;
  onApproval: (isAuthed: boolean) => void;
}) => {
  const auth = useAuth();

  const handleApprovalClick = () => {
    const checkCode = getQueryValueByKeys(constants.CHECK_CODE);
    const registerCode = getQueryValueByKeys(constants.REGISTER_CODE);
    if (!checkCode || !registerCode) {
      Log.error("checkCode or registerCode not exist: ", checkCode, registerCode);
      message.error("Register failed, please try again");
      return;
    }
    auth.registerApproval(username, checkCode, registerCode).then((isApproved) => {
      onApproval(isApproved);
    });
  };

  return (
    <CustomLayout contentClassName="register-approval">
      <VibeTitle
        title="Confirm Vibe registration?"
        subtitle="We received a request to register for a Vibe account. If you initiated this request, please click the Confirm button."
      />
      <ApprovalInfoList />
      <button className="button-primary-360" onClick={handleApprovalClick}>
        Confirm
      </button>
    </CustomLayout>
  );
};

const ApprovedContent = () => (
  <CustomLayout>
    <VibeTitle
      title="You have successfully registered."
      subtitle="Please return to the browser tab or window where you accessed the sign-in page."
    />
  </CustomLayout>
);

const RegisterApproval = () => {
  const [currentStatus, setCurrentStatus] = useState(RegisterState.SUBMIT_USERNAME);
  const [username, setUsername] = useState("");

  const handleUsernameSubmit = (username: string) => {
    setUsername(username);
    setCurrentStatus(RegisterState.UNDER_APPROVED);
  };

  const handleApproval = (isAuthed: boolean) => {
    if (isAuthed) {
      setCurrentStatus(RegisterState.APPROVED);
    } else {
      setCurrentStatus(RegisterState.EXPIRED);
    }
  };

  switch (currentStatus) {
    case RegisterState.UNDER_APPROVED:
      return <UnderApprovalContent username={username} onApproval={handleApproval} />;
    case RegisterState.APPROVED:
      return <ApprovedContent />;
    case RegisterState.EXPIRED:
      return <UnknownError subtitle="Registration failed. Please try again or contact support." />;
    default:
      return <SubmitUsername onUsernameSubmit={handleUsernameSubmit} />;
  }
};

export default RegisterApproval;
