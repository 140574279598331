import React from "react";
import "./styles.css";

const VibeAdminLogo = () => (
  <svg width="363" height="60" viewBox="0 0 242 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="admin-loading-spin">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87235 16.0789C9.27655 17.1428 8.92657 18.3976 8.92657 19.7118C8.92657 23.6801 12.1001 26.9212 16.0266 26.9212V31.9236C9.37001 31.9236 4 26.4335 4 19.7118C4 17.5 4.58705 15.3967 5.58973 13.6062L9.87235 16.0789Z"
        fill="url(#paint0_linear_105_39)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0735 19.7119C23.0735 18.3466 22.7401 17.1365 22.1458 16.1108L26.3922 13.5745C27.4516 15.4031 28.0001 17.4952 28.0001 19.7119C28.0001 26.4663 22.6254 31.9237 15.9735 31.9237V26.9212C19.9046 26.9212 23.0735 23.7035 23.0735 19.7119Z"
        fill="url(#paint1_linear_105_39)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0009 12.5024C13.3812 12.5024 11.0942 13.9317 9.86657 16.0924L5.59924 13.5926C7.67878 9.93255 11.5653 7.5 16.0009 7.5C20.4473 7.5 24.3241 9.96966 26.3973 13.5835L22.1404 16.1015C20.9064 13.9506 18.6098 12.5024 16.0009 12.5024Z"
        fill="url(#paint2_linear_105_39)"
      />
      <path
        d="M7.73301 17.3449C9.09408 17.3449 10.1975 16.2245 10.1975 14.8425C10.1975 13.4605 9.09408 12.3401 7.73301 12.3401C6.37193 12.3401 5.26855 13.4605 5.26855 14.8425C5.26855 16.2245 6.37193 17.3449 7.73301 17.3449Z"
        fill="#66CBCB"
      />
      <path
        d="M24.269 17.3449C25.6301 17.3449 26.7334 16.2245 26.7334 14.8425C26.7334 13.4604 25.6301 12.3401 24.269 12.3401C22.9079 12.3401 21.8046 13.4604 21.8046 14.8425C21.8046 16.2245 22.9079 17.3449 24.269 17.3449Z"
        fill="#FF6666"
      />
      <path
        d="M16.0008 31.9256C17.3605 31.9256 18.4627 30.8064 18.4627 29.4258C18.4627 28.0452 17.3605 26.926 16.0008 26.926C14.6412 26.926 13.5389 28.0452 13.5389 29.4258C13.5389 30.8064 14.6412 31.9256 16.0008 31.9256Z"
        fill="#6666CC"
      />
      <path
        d="M24.2688 16.7453C25.3039 16.7453 26.1429 15.8933 26.1429 14.8424C26.1429 13.7914 25.3039 12.9395 24.2688 12.9395C23.2338 12.9395 22.3948 13.7914 22.3948 14.8424C22.3948 15.8933 23.2338 16.7453 24.2688 16.7453Z"
        fill="#FFCC00"
      />
      <path
        d="M7.70549 16.7453C8.74052 16.7453 9.57957 15.8933 9.57957 14.8424C9.57957 13.7914 8.74052 12.9395 7.70549 12.9395C6.67047 12.9395 5.83142 13.7914 5.83142 14.8424C5.83142 15.8933 6.67047 16.7453 7.70549 16.7453Z"
        fill="#FFCC00"
      />
      <path
        d="M16.0009 31.3254C17.0359 31.3254 17.875 30.4734 17.875 29.4225C17.875 28.3715 17.0359 27.5195 16.0009 27.5195C14.9659 27.5195 14.1268 28.3715 14.1268 29.4225C14.1268 30.4734 14.9659 31.3254 16.0009 31.3254Z"
        fill="#FFCC00"
      />
    </g>
    <path
      d="M162.16 30.2998C159.33 30.2998 157 28.9972 157 26.2143C157 19.7307 167.645 23.6682 167.645 19.4643C167.645 17.3919 165.876 16.3557 163.517 16.3557C161.04 16.3557 159.241 17.5991 159.241 20.0564H157.442C157.442 16.3261 160.391 14.9051 163.517 14.9051C166.584 14.9051 169.415 16.1781 169.415 19.5235V30.0038H167.675V26.984C166.643 29.2932 164.431 30.2998 162.16 30.2998ZM158.799 26.1255C158.799 27.9314 160.303 28.7899 162.455 28.7899C165.109 28.7899 167.675 27.1024 167.675 23.9051V22.0695C165.109 23.9051 158.799 22.336 158.799 26.1255Z"
      fill="#111111"
    />
    <path
      d="M178.845 14.9051C181.293 14.9051 183.357 16.2077 184.536 18.3393V7.7998H186.306V30.0038H184.536V26.8656C183.357 28.9972 181.293 30.2998 178.845 30.2998C174.835 30.2998 171.797 27.132 171.797 22.6024C171.797 18.0728 174.835 14.9051 178.845 14.9051ZM179.052 16.5334C175.926 16.5334 173.567 18.961 173.567 22.6024C173.567 26.2439 175.926 28.6715 179.052 28.6715C182.177 28.6715 184.536 26.2439 184.536 22.6024C184.536 18.961 182.177 16.5334 179.052 16.5334Z"
      fill="#111111"
    />
    <path
      d="M199.789 21.4182C199.789 18.0432 198.315 16.4741 195.837 16.4741C193.36 16.4741 191.886 18.0432 191.886 21.4182V30.0038H190.117V15.2011H191.886V17.6584C192.741 15.7932 194.363 14.9051 196.309 14.9051C198.55 14.9051 200.379 15.9709 201.175 18.3097C201.942 16.0005 203.799 14.9051 206.07 14.9051C208.989 14.9051 211.23 16.7702 211.23 21.0334V30.0038H209.461V21.4182C209.461 18.0432 208.016 16.4741 205.598 16.4741C203.092 16.4741 201.558 18.0432 201.558 21.4182V30.0038H199.789V21.4182Z"
      fill="#111111"
    />
    <path
      d="M215.772 11.4116C214.946 11.4116 214.327 10.7899 214.327 9.96099C214.327 9.13204 214.946 8.51033 215.772 8.51033C216.627 8.51033 217.217 9.13204 217.217 9.96099C217.217 10.7899 216.627 11.4116 215.772 11.4116ZM216.656 30.0038H214.887V15.2011H216.656V30.0038Z"
      fill="#111111"
    />
    <path
      d="M222.219 15.2011V18.1024C223.133 15.9413 224.961 14.9051 227.202 14.9051C230.535 14.9051 233.012 16.9478 233.012 21.6847V30.0038H231.242V22.0695C231.242 18.2505 229.502 16.4741 226.731 16.4741C223.959 16.4741 222.219 18.2505 222.219 22.0695V30.0038H220.45V15.2011H222.219Z"
      fill="#111111"
    />
    <path
      d="M46.9852 27.6968L43.026 15.3513H40L44.9456 30.0393H49.0248L53.9704 15.3513H50.9444L46.9852 27.6968Z"
      fill="#111111"
    />
    <path d="M59.3158 15.3506H56.4102V30.052H59.3158V15.3506Z" fill="#111111" />
    <path
      d="M71.3136 15.0545C69.3674 15.0545 67.6744 15.9969 66.5413 17.5586V8H63.6353V30.0387H66.5413V27.8308C67.6744 29.3925 69.3674 30.3349 71.3136 30.3349C75.2994 30.3349 78.2988 27.1846 78.2988 22.688C78.2988 18.2048 75.2994 15.0545 71.3136 15.0545ZM70.967 27.6288C68.4342 27.6288 66.5413 25.6633 66.5413 22.688C66.5413 19.7261 68.4342 17.7471 70.967 17.7471C73.4998 17.7471 75.3928 19.7127 75.3928 22.688C75.3928 25.6633 73.4998 27.6288 70.967 27.6288Z"
      fill="#111111"
    />
    <path
      d="M87.617 15.0544C83.4845 15.0544 80.4585 18.2048 80.4585 22.7013C80.4585 27.1979 83.4845 30.3483 87.617 30.3483C90.9629 30.3483 93.4957 28.6789 94.4022 25.6497H91.2562C90.6697 26.9691 89.5099 27.7634 87.737 27.7634C85.2975 27.7634 83.6578 26.269 83.3112 23.7111H94.7221C94.7488 23.388 94.7755 23.0648 94.7755 22.7148C94.7755 18.2048 91.7494 15.0544 87.617 15.0544ZM83.3379 21.5839C83.6845 19.1202 85.2575 17.6393 87.617 17.6393C89.9498 17.6393 91.5495 19.1068 91.8961 21.5839H83.3379Z"
      fill="#111111"
    />
    <path
      d="M57.8623 13.0346C59.1873 13.0346 60.2614 11.9497 60.2614 10.6113C60.2614 9.27294 59.1873 8.18799 57.8623 8.18799C56.5373 8.18799 55.4631 9.27294 55.4631 10.6113C55.4631 11.9497 56.5373 13.0346 57.8623 13.0346Z"
      fill="#111111"
    />
    <path
      d="M109.234 30.3341C113.364 30.3341 116.476 27.1726 116.476 22.6518C116.476 18.1311 113.364 14.9695 109.234 14.9695C105.103 14.9695 101.991 18.1311 101.991 22.6518C101.991 27.1726 105.103 30.3341 109.234 30.3341ZM109.234 28.709C106.092 28.709 103.736 26.2861 103.736 22.6518C103.736 19.0175 106.092 16.5946 109.234 16.5946C112.375 16.5946 114.731 19.0175 114.731 22.6518C114.731 26.2861 112.375 28.709 109.234 28.709Z"
      fill="#111111"
    />
    <path
      d="M121.22 15.265H119.474V30.0387H121.22V22.12C121.22 18.3083 122.936 16.5355 125.67 16.5355C128.404 16.5355 130.12 18.3083 130.12 22.12V30.0387H131.865V21.7358C131.865 17.0083 129.422 14.9695 126.135 14.9695C123.925 14.9695 122.121 16.0036 121.22 18.1606V15.265Z"
      fill="#111111"
    />
    <path
      d="M148.764 23.2723C148.793 23.0655 148.793 22.8586 148.793 22.6518C148.793 18.1311 145.797 14.9695 141.754 14.9695C137.711 14.9695 134.715 18.1311 134.715 22.6518C134.715 27.1726 137.711 30.3341 141.754 30.3341C144.953 30.3341 147.484 28.5613 148.386 25.6066H146.524C145.768 27.5567 144.052 28.7681 141.812 28.7681C138.787 28.7681 136.664 26.6407 136.431 23.2723H148.764ZM141.754 16.5355C144.721 16.5355 146.844 18.6334 147.077 21.9722H136.431C136.693 18.6334 138.787 16.5355 141.754 16.5355Z"
      fill="#111111"
    />
    <defs>
      <linearGradient
        id="paint0_linear_105_39"
        x1="6.56182"
        y1="21.7605"
        x2="8.53321"
        y2="13.175"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6666CC" />
        <stop offset="1" stopColor="#6666CC" stopOpacity="0.3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_105_39"
        x1="21.9868"
        y1="28.1432"
        x2="14.1448"
        y2="31.8317"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6666" />
        <stop offset="1" stopColor="#FF6666" stopOpacity="0.3" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_105_39"
        x1="19.0111"
        y1="11.8007"
        x2="23.7656"
        y2="18.0452"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#66CCCC" />
        <stop offset="1" stopColor="#66CCCC" stopOpacity="0.3" />
      </linearGradient>
    </defs>
  </svg>
);

export default VibeAdminLogo;
