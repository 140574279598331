export const routes = {
  corsSync: "/",
  checkToken: "/check-token",
  login: "/login",
  logout: "/logout",
  ssoCheck: "/sso-check",
  emailConfirmCheck: "/email-confirm-check",
  loginApproval: "/login-approval",
  registerApproval: "/register-approval",
  tvApproval: "/tv-approval",
  register: "/register",
  createWorkspace: "/new-workspace",
  noWorkspaceLoading: "no-workspace",
  registerByInvitation: "/register-by-invitation",
  selectWorkspace: "/select-workspace",
  joinTeamError: "/join-team-error",
  unknownError: "/404",
};
