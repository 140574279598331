import React from "react";
import "./styles.css";

const VibeCanvasLogo = () => (
  <svg id="canvas" width="320" viewBox="0 0 349 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <style></style>
    <g clipPath="url(#clip0_158_672)">
      <path
        d="M80.637 44.2016L73.4622 21.824H67.9784L76.9409 48.4477H84.3331L93.2956 21.824H87.8118L80.637 44.2016Z"
        fill="#111111"
      />
      <path d="M102.983 21.824H97.7164V48.4722H102.983V21.824Z" fill="#111111" />
      <path
        d="M124.725 21.2872C121.198 21.2872 118.13 22.9954 116.076 25.8262V8.5H110.81V48.4478H116.076V44.4457C118.13 47.2764 121.198 48.9846 124.725 48.9846C131.948 48.9846 137.384 43.2743 137.384 35.1237C137.384 26.9975 131.948 21.2872 124.725 21.2872ZM124.097 44.0796C119.507 44.0796 116.076 40.5168 116.076 35.1237C116.076 29.755 119.507 26.1678 124.097 26.1678C128.687 26.1678 132.117 29.7306 132.117 35.1237C132.117 40.5168 128.687 44.0796 124.097 44.0796Z"
        fill="#111111"
      />
      <path
        d="M154.27 21.2871C146.781 21.2871 141.297 26.9974 141.297 35.148C141.297 43.2987 146.781 49.009 154.27 49.009C160.333 49.009 164.923 45.983 166.566 40.4923H160.865C159.802 42.8838 157.7 44.3236 154.487 44.3236C150.066 44.3236 147.095 41.6148 146.467 36.9783H167.146C167.194 36.3926 167.242 35.8069 167.242 35.1724C167.242 26.9974 161.759 21.2871 154.27 21.2871ZM146.515 33.1226C147.143 28.6568 149.994 25.9725 154.27 25.9725C158.497 25.9725 161.396 28.6324 162.024 33.1226H146.515Z"
        fill="#111111"
      />
      <path
        d="M193.236 46.0563C187.656 46.0563 183.476 41.6882 183.476 35.1237C183.476 28.5593 187.631 24.1912 193.236 24.1912C198.043 24.1912 202.054 27.2904 202.73 32.3906H205.991C205.242 25.7286 199.928 21.2628 193.236 21.2628C185.844 21.2628 180.312 26.9731 180.312 35.1237C180.312 43.2744 185.844 48.9847 193.236 48.9847C200.145 48.9847 205.581 44.2505 206.112 37.2712H202.851C202.247 42.7619 198.188 46.0563 193.236 46.0563Z"
        fill="#111111"
      />
      <path
        d="M221.766 21.2871C216.186 21.2871 210.895 23.8494 210.895 30.5603H214.108C214.108 26.1433 217.321 23.8982 221.766 23.8982C225.994 23.8982 229.159 25.7529 229.159 29.4865C229.159 37.0515 210.122 29.9746 210.122 41.6392C210.122 46.6419 214.277 48.9846 219.351 48.9846C223.409 48.9846 227.371 47.1787 229.207 43.0058V48.4477H232.323V29.5841C232.299 23.581 227.25 21.2871 221.766 21.2871ZM229.207 37.4907C229.207 43.2498 224.617 46.2758 219.882 46.2758C216.041 46.2758 213.335 44.7384 213.335 41.4928C213.335 34.6844 224.617 37.4907 229.207 34.1963V37.4907Z"
        fill="#111111"
      />
      <path
        d="M250.562 21.2871C246.552 21.2871 243.291 23.1417 241.648 27.0462V21.824H238.483V48.4721H241.648V34.1963C241.648 27.3147 244.764 24.1179 249.717 24.1179C254.669 24.1179 257.785 27.3147 257.785 34.1963V48.4721H260.95V33.513C260.95 24.972 256.505 21.2871 250.562 21.2871Z"
        fill="#111111"
      />
      <path
        d="M276.073 46.1051L266.748 21.824H263.414L273.971 48.4477H278.198L288.731 21.824H285.422L276.073 46.1051Z"
        fill="#111111"
      />
      <path
        d="M302.453 21.2871C296.872 21.2871 291.582 23.8494 291.582 30.5603H294.795C294.795 26.1433 298.008 23.8982 302.453 23.8982C306.68 23.8982 309.845 25.7529 309.845 29.4865C309.845 37.0515 290.809 29.9746 290.809 41.6392C290.809 46.6419 294.964 48.9846 300.037 48.9846C304.096 48.9846 308.057 47.1787 309.893 43.0058V48.4477H313.01V29.5841C312.986 23.581 307.937 21.2871 302.453 21.2871ZM309.893 37.4907C309.893 43.2498 305.303 46.2758 300.569 46.2758C296.727 46.2758 294.022 44.7384 294.022 41.4928C294.022 34.6844 305.303 37.4907 309.893 34.1963V37.4907Z"
        fill="#111111"
      />
      <path
        d="M321.368 28.5348C321.368 25.6064 323.905 23.8982 327.746 23.8982C331.804 23.8982 334.752 26.2409 334.752 29.3889H338.013C338.013 24.7523 333.64 21.2871 327.722 21.2871C322.286 21.2871 318.179 23.9958 318.179 28.6324C318.179 39.1257 335.428 33.3666 335.428 41.3708C335.428 44.7384 332.843 46.4222 328.519 46.4222C324.146 46.4222 320.813 44.348 320.813 40.2971H317.551C317.551 45.8854 322.238 48.9846 328.519 48.9846C334.486 48.9846 338.641 46.2026 338.641 41.2488C338.689 30.5603 321.368 36.0998 321.368 28.5348Z"
        fill="#111111"
      />
      <path
        d="M100.35 17.6268C102.751 17.6268 104.698 15.6602 104.698 13.2342C104.698 10.8083 102.751 8.84167 100.35 8.84167C97.9482 8.84167 96.0013 10.8083 96.0013 13.2342C96.0013 15.6602 97.9482 17.6268 100.35 17.6268Z"
        fill="#111111"
      />
      <g clipPath="url(#clip1_158_672)">
        <path
          d="M43.536 23.5863C46.3131 23.5863 48.5644 21.335 48.5644 18.5579C48.5644 15.7808 46.3131 13.5295 43.536 13.5295C40.7589 13.5295 38.5076 15.7808 38.5076 18.5579C38.5076 21.335 40.7589 23.5863 43.536 23.5863Z"
          fill="#FFCC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.9217 14.8317C46.0402 14.0104 44.8382 13.5296 43.556 13.5296C40.7914 13.5296 38.5276 15.7733 38.5276 18.558C38.5276 19.9002 39.0685 21.1222 39.9099 22.0237C39.8498 21.9436 39.7697 21.8835 39.6896 21.8234C36.1036 18.3576 31.3156 16.1139 26.0067 15.7733C25.546 15.7332 25.0852 15.7332 24.6244 15.7332C20.4174 15.7332 16.4908 16.9352 13.1653 18.9987C16.9716 12.7683 23.8631 8.5813 31.6762 8.5813C33.7396 8.5813 35.723 8.86177 37.6261 9.40267C41.1721 10.4244 44.3574 12.3075 46.9217 14.8317Z"
          fill="#66CCCC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0368 30.7182C20.0368 30.6381 20.0368 30.578 20.0368 30.4979C20.0368 30.4177 20.0368 30.3376 20.0368 30.2575C20.0368 29.8368 20.0568 29.4361 20.0969 29.0354C20.0969 29.0154 20.0969 29.0154 20.0969 29.0154C20.7179 23.1656 25.6662 18.618 31.6762 18.618C34.7814 18.618 37.5861 19.8401 39.6896 21.8034C36.1036 18.3376 31.3156 16.0938 26.0067 15.7533C25.546 15.7132 25.0852 15.7132 24.6244 15.7132C20.4174 15.7132 16.4908 16.9152 13.1653 18.9786C11.1619 22.2842 10 26.1506 10 30.2775C10 42.2375 19.7363 51.9537 31.6962 51.9537C33.0785 51.9537 34.4408 51.8135 35.743 51.5731C26.6678 48.9687 20.0368 40.6148 20.0368 30.7182Z"
          fill="#6666CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.5243 42.0171C48.5243 43.2992 48.0435 44.4812 47.2422 45.3627C47.2422 45.3627 47.0018 45.6231 46.8816 45.7233C45.7597 46.8251 44.5377 47.7867 43.2154 48.6281C40.9717 50.0505 38.4475 51.0522 35.743 51.5731C26.6878 48.9687 20.0367 40.6148 20.0367 30.7182C20.0367 30.6381 20.0367 30.578 20.0367 30.4979C20.1569 36.8084 25.3256 41.9169 31.6762 41.9169C34.8815 41.9169 37.7864 40.6148 39.9099 38.5112C39.93 38.4912 39.95 38.4712 39.97 38.4511L39.9901 38.4311C40.8916 37.5496 42.1336 36.9887 43.4959 36.9887C44.5377 36.9887 45.4993 37.3092 46.3006 37.8501C47.6428 38.7516 48.5243 40.2942 48.5243 42.0171Z"
          fill="#FF6666"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_158_672">
        <rect width="328.689" height="43.535" fill="#111111" transform="translate(10 8.5)" />
      </clipPath>
      <clipPath id="clip1_158_672">
        <rect width="38.5644" height="43.3724" fill="#111111" transform="translate(10 8.5813)" />
      </clipPath>
    </defs>
  </svg>
);

export default VibeCanvasLogo;
