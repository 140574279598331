import React, { useEffect, useRef, useState } from "react";

import ArrayIcon from "./ArrowIcon";
import CustomImg from "@/components/CustomImg";
import defaultAvatar from "./WorkspaceAvatar";

import { ITeamInfoObj } from "@/api/interface";
import Log from "@/utils/Log";

import constants from "@/constants";
import "./styles.css";

export interface ISelectionItem extends ITeamInfoObj {
  href: string;
}

const roleMapping: Record<string, string> = {
  owner: "Owner",
  admin: "Admin",
  editor: "Member",
};

const Divider = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
    <path d="M1 0V16" stroke="#E5E5E5" />
  </svg>
);

const SelectionItem = ({
  teamId,
  href,
  iconUrl,
  displayName,
  role,
  memberNum = 1,
}: ISelectionItem) => {
  const threshold = 216;
  const nameRef = useRef<HTMLDivElement>(null);
  const [animationClass, setAnimationClass] = useState<string>("");

  useEffect(() => {
    if (nameRef?.current && nameRef.current.offsetWidth > threshold) {
      setAnimationClass("overflow-ellipsis");
    }
  }, [nameRef]);

  function onSelectionClicked() {
    Log.debug("save worksapce selection", teamId);
    localStorage.setItem(constants.TEAM_ID, teamId);
  }

  return (
    <a className="workspace-item" href={href} onClick={onSelectionClicked}>
      <CustomImg className="avatar" src={iconUrl} alt="avatar" defaultImg={defaultAvatar} />
      <div className={`workspace-info ${animationClass}`}>
        <div ref={nameRef} className="name">
          {displayName}
        </div>
        {role && (
          <div className="team-info">
            <div className={`role ${role}`}>{roleMapping[role]}</div>
            <Divider />
            <div className="member-num">{`${memberNum} ${
              memberNum > 1 ? "members" : "member"
            }`}</div>
          </div>
        )}
      </div>
      <ArrayIcon />
    </a>
  );
};

export default SelectionItem;
