import React, { ReactNode, useState } from "react";
import "./styles.css";

interface CustomImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  defaultImg: ReactNode | string;
}
const MAX_RELOAD_COUNT = 5;

const CustomImg = ({ className, defaultImg, src, ...props }: CustomImgProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [reloadCount, setReloadCount] = useState(MAX_RELOAD_COUNT);
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageLoad = () => setIsLoaded(true);

  const handleImageError = () => {
    if (src && reloadCount > 0) {
      setTimeout(() => {
        const timestamp = new Date().getTime();
        setImageSrc(`${src}?timestamp=${timestamp}`);
        setReloadCount(reloadCount - 1);
      }, 1000);
    } else {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <img
        {...props}
        className={`custom-img ${isLoaded ? "" : "hidden"} ${className || ""}`}
        src={imageSrc}
        onError={handleImageError}
        onLoad={handleImageLoad}
      />
      {!isLoaded && <div className={`custom-img ${className || ""}`}>{defaultImg}</div>}
    </>
  );
};

export default CustomImg;
