import React, { useEffect, useState } from "react";

import CustomLayout from "@/components/CustomLayout";
import VibeTitle from "@/components/VibeTitle";
import HintWrapper from "./components/HintWrapper";
import SelectionItem, { ISelectionItem } from "./components/SelectionItem";

import { useAuth, useRedirectNavigation } from "@/hooks";
import constants, { routes } from "@/constants";
import "./styles.css";
import { addRedirectParam } from "@/utils";
import Log from "@/utils/Log";

const subtitle = (isAdmin: boolean) => (
  <>
    {`Choose the workspace that you want to use when signing in to Vibe${
      isAdmin ? " One Admin" : ""
    }. You can switch to
    other workspace anytime.`}
    <HintWrapper />
  </>
);

const SelectWorkspace = () => {
  const auth = useAuth();
  const navigate = useRedirectNavigation();
  const isAdmin = auth.signInFrom === "admin";
  const [teamInfoList, setTeamList] = useState<Array<ISelectionItem>>([]);
  const accessToken = localStorage.getItem(constants.ACCESS_TOKEN) || "";

  useEffect(() => {
    if (!auth?.teamInfoList?.length) {
      Log.debug("team info list is empty");
      navigate(routes.corsSync);
    }

    const teamList = auth.teamInfoList
      .map((team) => ({
        ...team,
        href: addRedirectParam(auth.redirectUrl, team.teamId, accessToken),
      }))
      .sort((a, b) => {
        if (a.personal !== b.personal) {
          return a.personal ? -1 : 1;
        }
        return a.displayName.localeCompare(b.displayName);
      });
    Log.debug("teamList: ", teamList);
    setTeamList(teamList);
  }, [auth]);

  return (
    <CustomLayout contentClassName="select-workspace">
      <VibeTitle title="Select a workspace" subtitle={subtitle(isAdmin)} />
      <div className="selection-wrapper">
        {teamInfoList.map((team) => (
          <SelectionItem key={team.teamId} {...team} />
        ))}
      </div>
    </CustomLayout>
  );
};

export default SelectWorkspace;
