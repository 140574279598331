import constants, { redirectUrls, serverUrls } from "@/constants";

export const getRedirectDomainUrl = (from?: SignInApps) => {
  const { canvas, admin, website } = constants.SIGN_IN_APP_NAMES;
  const currentEnv = (process.env.REACT_APP_APP_ENV as EnvType) || "dev";

  switch (from) {
    case canvas:
      return redirectUrls.CANVAS_URL[currentEnv];
    case admin:
      return redirectUrls.PORTAL_URL[currentEnv];
    case website:
      return redirectUrls.WEBSITE_URL[currentEnv];
    default:
      return redirectUrls.VIBEONE_URL[currentEnv];
  }
};
export const currentServerUrl = (() => {
  switch (process.env.REACT_APP_APP_ENV) {
    case "prod":
      return serverUrls.prodServerUrl;
    case "beta":
      return serverUrls.betaServerUrl;
    default:
      return serverUrls.devServerUrl;
  }
})();

const serverHeaders = {
  Accept: "text/plain, application/json",
  "User-Agent": navigator.userAgent,
  "Content-Language": navigator.language === "zh-CN" ? "zh-cn" : "en-us",
  "Cache-Control": "no-cache",
  "Referrer Policy": "no-referrer",
};

const serverConfig = {
  baseURL: currentServerUrl.restBaseUrl,
  headers: serverHeaders,
  timeout: 30 * 1000,
  withCredentials: true,
};

export default serverConfig;
