export const BASIC_URL = Object.freeze({
  prod: "https://account.vibe.us",
  beta: "https://account.vibe-beta.com",
  dev: "https://dev-account.vibe-beta.com",
});

const CANVAS_URL = {
  prod: "https://app.vibe.us",
  beta: "https://app.vibe-beta.com",
  dev: "https://dev-app.vibe-beta.com",
};

const PORTAL_URL = Object.freeze({
  prod: "https://one.vibe.us/admin/board",
  beta: "https://one.vibe-beta.com/admin/board",
  dev: "https://dev-one.vibe-beta.com/admin/board",
});

const VIBEONE_URL = Object.freeze({
  prod: "https://one.vibe.us/boards",
  beta: "https://one.vibe-beta.com/boards",
  dev: "https://dev-one.vibe-beta.com/boards",
});

const WEBSITE_URL = Object.freeze({
  prod: "https://vibe.us",
  beta: "https://vibe-beta.com/",
  dev: "https://vibe-beta.com/",
});

export const redirectUrls = {
  CANVAS_URL,
  PORTAL_URL,
  VIBEONE_URL,
  WEBSITE_URL,
};
