import { ReactNode } from "react";
import { message as antdMessage } from "antd";
import { WarningIcon, SuccessIcon, ErrorIcon } from "./MessageIcons";

antdMessage.config({
  duration: 1.5,
  maxCount: 3,
});

const message = {
  success: (content: ReactNode, duration?: number) => {
    antdMessage.success({ content, duration, icon: SuccessIcon });
  },
  error: (content: ReactNode, duration?: number) => {
    antdMessage.error({ content, duration, icon: ErrorIcon });
  },
  warning: (content: ReactNode, duration?: number) => {
    antdMessage.warning({ content, duration, icon: WarningIcon });
  },
};

export default message;
