/* eslint-disable @typescript-eslint/no-explicit-any */
import { bugsnagReactError } from "./Bugsnag";
import { getTimeString } from ".";

class CustomLog {
  private isProduction: boolean;
  private isLocalEnv: boolean;

  constructor() {
    this.isProduction = process.env.REACT_APP_APP_ENV === "prod";
    this.isLocalEnv = process.env.NODE_ENV === "development";
  }

  private shouldLog(): boolean {
    return this.isLocalEnv || !this.isProduction;
  }

  info(message?: any, ...optionalParams: any | any[]): void {
    if (this.shouldLog()) {
      const msg = `[USI] Info : ${getTimeString(Date.now())} ${message}`;
      console.info(msg, ...optionalParams);
    }
  }

  debug(message?: any, ...optionalParams: any | any[]): void {
    if (this.shouldLog()) {
      const msg = `[USI] Debug : ${getTimeString(Date.now())} ${message}`;
      console.debug(msg, ...optionalParams);
    }
  }

  error(message?: any, ...optionalParams: any | any[]): void {
    const msg = `[USI] Error : ${getTimeString(Date.now())} ${message}`;
    console.error(msg, ...optionalParams);
    bugsnagReactError(message);
  }
}

const Log = new CustomLog();

export default Log;
