import React from "react";

import Bugsnag from "@bugsnag/browser";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

const BUGSNAG_API_KEY = "ebe13bb57dba25c3b967648327e53f92";

function initializeBugsnag() {
  let stage = process.env.REACT_APP_APP_ENV;
  if (process.env.NODE_ENV === "development" || !stage) {
    stage = "dev";
  }

  const timeStamp = Math.floor((Date.now() / 1000 - 1514764800) / 60);
  const commitHash = process.env.REACT_APP_GIT_SHA || "adhoc";
  const appVersion = `${timeStamp}.${commitHash}`;

  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    appVersion,
    releaseStage: stage,
    enabledReleaseStages: ["prod", "beta"],
    plugins: [new BugsnagPluginReact(React)],
  });

  BugsnagPerformance.start({ apiKey: BUGSNAG_API_KEY });
}
export type LogContext = {
  name: string;
  [key: string]: unknown;
};

export const bugsnagReactError = (error: Error) => Bugsnag.notify(error);

initializeBugsnag();
