import React from "react";
import { InputTypes, PrefixIconInterface } from "../../interface";
const getIconPath = (type: InputTypes, color: string) => {
  switch (type) {
    case "email":
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3337 13.75C12.4132 13.75 11.667 14.4962 11.667 15.4167V24.5833C11.667 25.5038 12.4132 26.25 13.3337 26.25H26.667C27.5875 26.25 28.3337 25.5038 28.3337 24.5833V15.4167C28.3337 14.4962 27.5875 13.75 26.667 13.75H13.3337ZM13.3337 15.4167H26.667V16.0544L20.5781 20.1136C20.2283 20.3469 19.7724 20.3469 19.4225 20.1136L13.3337 16.0544V15.4167ZM13.3337 17.5567V24.5833H26.667V17.5567L21.2715 21.1537C20.5018 21.6669 19.4989 21.6669 18.7291 21.1537L13.3337 17.5567Z"
          fill={color}
        />
      );
    case "username":
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9167 15.416C17.9167 14.2654 18.8494 13.3327 20 13.3327C21.1506 13.3327 22.0833 14.2654 22.0833 15.416C22.0833 16.5666 21.1506 17.4993 20 17.4993C18.8494 17.4993 17.9167 16.5666 17.9167 15.416ZM20 11.666C17.9289 11.666 16.25 13.3449 16.25 15.416C16.25 17.4871 17.9289 19.166 20 19.166C22.0711 19.166 23.75 17.4871 23.75 15.416C23.75 13.3449 22.0711 11.666 20 11.666ZM15.8333 19.9993C13.9924 19.9993 12.5 21.4917 12.5 23.3327V26.666C12.5 27.5865 13.2462 28.3327 14.1667 28.3327H25.8333C26.7538 28.3327 27.5 27.5865 27.5 26.666V23.3327C27.5 21.4917 26.0076 19.9993 24.1667 19.9993H15.8333ZM14.1667 23.3327C14.1667 22.4122 14.9129 21.666 15.8333 21.666H24.1667C25.0871 21.666 25.8333 22.4122 25.8333 23.3327V26.666H14.1667V23.3327Z"
          fill={color}
        />
      );
    default:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8333 15.2077C15.8333 12.9065 17.6988 11.041 20 11.041C22.3012 11.041 24.1667 12.9065 24.1667 15.2077V18.3327H25.8333C26.7538 18.3327 27.5 19.0789 27.5 19.9993V26.666C27.5 27.5865 26.7538 28.3327 25.8333 28.3327H14.1667C13.2462 28.3327 12.5 27.5865 12.5 26.666V19.9993C12.5 19.0789 13.2462 18.3327 14.1667 18.3327H15.8333V15.2077ZM22.5 15.2077V18.3327H17.5V15.2077C17.5 13.827 18.6193 12.7077 20 12.7077C21.3807 12.7077 22.5 13.827 22.5 15.2077ZM14.1667 19.9993V26.666H25.8333V19.9993H14.1667ZM19.375 21.2493V25.416H20.625V21.2493H19.375Z"
          fill={color}
        />
      );
  }
};
const InputPrefixIcon = (props: PrefixIconInterface) => {
  const { isInvalid = false, type = "email" } = props;
  const color = isInvalid ? "#fc4747" : "#727272";

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      {getIconPath(type, color)}
    </svg>
  );
};

export default InputPrefixIcon;
