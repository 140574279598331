import React from "react";
import { Tooltip } from "antd";

const QuestionIcon = () => (
  <svg
    className="question-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2 12C7.2 9.34903 9.34903 7.2 12 7.2C14.651 7.2 16.8 9.34903 16.8 12C16.8 14.651 14.651 16.8 12 16.8C9.34903 16.8 7.2 14.651 7.2 12ZM12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6ZM12.6 14.4V15.6H11.4V14.4H12.6ZM9.6 10.8C9.6 9.474 10.674 8.4 12 8.4C13.326 8.4 14.4 9.474 14.4 10.8C14.4 11.5698 13.926 11.984 13.4645 12.3873C13.0266 12.77 12.6 13.1428 12.6 13.8H11.4C11.4 12.7072 11.9653 12.274 12.4623 11.893C12.8521 11.5942 13.2 11.3275 13.2 10.8C13.2 10.14 12.66 9.6 12 9.6C11.34 9.6 10.8 10.14 10.8 10.8H9.6Z"
      fill="#111111"
    />
  </svg>
);

const HintText = () => (
  <div className="hint-text">
    <em>Note: </em>
    To select another workspace after signing in, tap the current workspace name.
  </div>
);

const HintWrapper = () => (
  <Tooltip overlayClassName="tooltip-hint" placement="bottom" title={HintText} trigger="hover">
    <span className="subtitle-hint-icon">
      <QuestionIcon />
    </span>
  </Tooltip>
);
export default HintWrapper;
