import React from "react";
import UnknownError from "@/components/UnknownError";

const JoinTeamError = () => {
  return (
    <UnknownError subtitle="This link has expired. Please submit a new request to receive a new link via email." />
  );
};

export default JoinTeamError;
