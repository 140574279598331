import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { AuthProvider, useAuth } from "./hooks";
import NotFoundPage, { JoinTeamError } from "./pages/NotFoundPage";
import Register from "./pages/Register";
import Login from "./pages/Login";
import SSOConfirm from "./pages/SSOConfirm";
import EmailConfirm from "./pages/EmailConfirm";
import EmailLoginApproval from "./pages/EmailLoginApproval";
import RegisterApproval from "./pages/RegisterApproval";
import CreateWorkspace from "./pages/CreateWorkspace";
import CorsLoginSync from "./pages/CorsLoginSync";
import RegisterByInvitation from "./pages/RegisterByInvitation";
import SelectWorkspace from "./pages/SelectWorkspace";
import Logout from "./pages/Logout";
import Loading from "./components/Loading";
import NoWorkspaceLoading from "./pages/CreateWorkspace/NoWorkspaceLoading";

import { routes } from "./constants";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path={routes.corsSync} element={<CorsLoginSync />} />
          <Route path={routes.checkToken} element={<CorsLoginSync isCheckToken />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.logout} element={<Logout />} />
          <Route path={routes.ssoCheck} element={<SSOConfirm />} />
          <Route path={routes.emailConfirmCheck} element={<EmailConfirm />} />
          <Route path={routes.loginApproval} element={<EmailLoginApproval />} />
          <Route path={routes.tvApproval} element={<EmailLoginApproval isTvLogin />} />
          <Route path={routes.registerApproval} element={<RegisterApproval />} />
          <Route path={routes.register} element={<Register />} />
          <Route path={routes.createWorkspace} element={<CreateWorkspace />} />
          <Route path={routes.noWorkspaceLoading} element={<NoWorkspaceLoading />} />
          <Route path={routes.registerByInvitation} element={<RegisterByInvitation />} />
          <Route path={routes.selectWorkspace} element={<SelectWorkspace />} />
          <Route path={routes.joinTeamError} element={<JoinTeamError />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function Layout() {
  const auth = useAuth();

  return (
    <div className="container">
      <Outlet />
      {auth.isLoading && <Loading />}
    </div>
  );
}
