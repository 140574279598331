import React from "react";
import { Link } from "react-router-dom";
import type { LinkProps } from "react-router-dom";

import { redirectParams } from "@/utils";

const CustomLink = ({ to, ...props }: LinkProps) => {
  const queryParams = redirectParams();
  const pathWithRedirectQuery = `${to}?${queryParams.toString()}`;

  return <Link to={pathWithRedirectQuery} {...props} />;
};

export default CustomLink;
