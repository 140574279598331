import React from "react";

const VibeLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="85" height="24" viewBox="0 0 145 40" fill="none">
    <path
      d="M66.5125 36.7379H59.5817L51.1641 11.9609H56.3135L63.0471 32.7744L69.7785 11.9609H74.9279L66.5125 36.7379Z"
      fill="#111111"
    />
    <path d="M84.0554 11.9609H79.1055V36.7379H84.0554V11.9609Z" fill="#111111" />
    <path
      d="M104.43 37.2348C101.112 37.2348 98.2423 35.6489 96.3113 33.0217V36.7379H91.3613V0.0273438H96.3113V15.6772C98.2423 13.05 101.114 11.4641 104.43 11.4641C111.211 11.4641 116.31 16.7662 116.31 24.3483C116.31 31.9327 111.211 37.2348 104.43 37.2348ZM96.3113 24.3506C96.3113 29.3554 99.5296 32.6746 103.836 32.6746C108.142 32.6746 111.361 29.3554 111.361 24.3506C111.361 19.3457 108.142 16.0243 103.836 16.0243C99.5296 16.0243 96.3113 19.3457 96.3113 24.3506Z"
      fill="#111111"
    />
    <path
      d="M144.233 26.0308H124.827C125.421 30.3414 128.193 32.8688 132.352 32.8688C135.371 32.8688 137.352 31.5303 138.342 29.3001H143.686C142.152 34.4048 137.846 37.2294 132.152 37.2294C125.124 37.2294 119.975 31.9273 119.975 24.3452C119.975 16.763 125.124 11.4609 132.152 11.4609C139.183 11.4609 144.33 16.763 144.33 24.3452C144.33 24.9418 144.282 25.4863 144.233 26.0308ZM124.875 22.4644H139.43C138.836 18.3012 136.112 15.8237 132.152 15.8237C128.143 15.8237 125.469 18.3012 124.875 22.4644Z"
      fill="#111111"
    />
    <path
      d="M81.5913 8.16749C83.8445 8.16749 85.671 6.33913 85.671 4.08374C85.671 1.82835 83.8445 0 81.5913 0C79.3382 0 77.5117 1.82835 77.5117 4.08374C77.5117 6.33913 79.3382 8.16749 81.5913 8.16749Z"
      fill="#111111"
    />
    <path
      d="M66.5125 36.7379H59.5817L51.1641 11.9609H56.3135L63.0471 32.7744L69.7785 11.9609H74.9279L66.5125 36.7379Z"
      fill="#111111"
    />
    <path d="M84.0554 11.9609H79.1055V36.7379H84.0554V11.9609Z" fill="#111111" />
    <path
      d="M104.43 37.2348C101.112 37.2348 98.2423 35.6489 96.3113 33.0217V36.7379H91.3613V0.0273438H96.3113V15.6772C98.2423 13.05 101.114 11.4641 104.43 11.4641C111.211 11.4641 116.31 16.7662 116.31 24.3483C116.31 31.9327 111.211 37.2348 104.43 37.2348ZM96.3113 24.3506C96.3113 29.3554 99.5296 32.6746 103.836 32.6746C108.142 32.6746 111.361 29.3554 111.361 24.3506C111.361 19.3457 108.142 16.0243 103.836 16.0243C99.5296 16.0243 96.3113 19.3457 96.3113 24.3506Z"
      fill="#111111"
    />
    <path
      d="M144.233 26.0308H124.827C125.421 30.3414 128.193 32.8688 132.352 32.8688C135.371 32.8688 137.352 31.5303 138.342 29.3001H143.686C142.152 34.4048 137.846 37.2294 132.152 37.2294C125.124 37.2294 119.975 31.9273 119.975 24.3452C119.975 16.763 125.124 11.4609 132.152 11.4609C139.183 11.4609 144.33 16.763 144.33 24.3452C144.33 24.9418 144.282 25.4863 144.233 26.0308ZM124.875 22.4644H139.43C138.836 18.3012 136.112 15.8237 132.152 15.8237C128.143 15.8237 125.469 18.3012 124.875 22.4644Z"
      fill="#111111"
    />
    <path
      d="M81.5913 8.16749C83.8445 8.16749 85.671 6.33913 85.671 4.08374C85.671 1.82835 83.8445 0 81.5913 0C79.3382 0 77.5117 1.82835 77.5117 4.08374C77.5117 6.33913 79.3382 8.16749 81.5913 8.16749Z"
      fill="#111111"
    />
    <path
      d="M24.4777 4.08765C24.4777 1.83252 26.3045 0.00390625 28.5574 0.00390625H4.07962C1.82676 0.00390625 0 1.83252 0 4.08765C0 6.34278 1.82676 8.17139 4.07962 8.17139H28.5574C26.3045 8.17139 24.4777 6.34278 24.4777 4.08765Z"
      fill="#66CCCC"
    />
    <path
      d="M25.9697 33.8758L13.7309 12.6562C14.8573 14.6096 14.1887 17.1075 12.2373 18.2351C10.2859 19.3627 7.79049 18.6934 6.66406 16.74L18.9029 37.9596C20.0294 39.913 22.5247 40.5823 24.4761 39.4547C26.4276 38.3271 27.0962 35.8292 25.9697 33.8758Z"
      fill="#6666CC"
    />
    <path
      d="M42.8375 0.547691C40.8861 -0.579877 38.3907 0.0894038 37.2643 2.04279L25.0254 23.2624C26.1518 21.309 28.6472 20.6397 30.5986 21.7673C32.55 22.8948 33.2186 25.3927 32.0922 27.3461L44.3311 6.12654C45.4575 4.17315 44.7889 1.67753 42.8375 0.547691Z"
      fill="#FF6666"
    />
    <path
      d="M28.5562 8.16749C30.8093 8.16749 32.6358 6.33913 32.6358 4.08374C32.6358 1.82835 30.8093 0 28.5562 0C26.3031 0 24.4766 1.82835 24.4766 4.08374C24.4766 6.33913 26.3031 8.16749 28.5562 8.16749Z"
      fill="#FFCC00"
    />
    <path
      d="M10.1968 18.7808C12.4499 18.7808 14.2764 16.9524 14.2764 14.697C14.2764 12.4416 12.4499 10.6133 10.1968 10.6133C7.9437 10.6133 6.11719 12.4416 6.11719 14.697C6.11719 16.9524 7.9437 18.7808 10.1968 18.7808Z"
      fill="#FFCC00"
    />
    <path
      d="M28.5562 29.3862C30.8093 29.3862 32.6358 27.5579 32.6358 25.3025C32.6358 23.0471 30.8093 21.2188 28.5562 21.2188C26.3031 21.2188 24.4766 23.0471 24.4766 25.3025C24.4766 27.5579 26.3031 29.3862 28.5562 29.3862Z"
      fill="#FFCC00"
    />
  </svg>
);

export default VibeLogo;
