// server url
const prodServerUrl = Object.freeze({
  name: "prod",
  restBaseUrl: "https://api.vibe.us/v1",
  wsBaseUrl: "wss://wss.vibe.us",
});

const betaServerUrl = Object.freeze({
  name: "beta",
  restBaseUrl: "https://api.vibe-beta.com/v1",
  wsBaseUrl: "wss://wss.vibe-beta.com",
});

const devServerUrl = Object.freeze({
  name: "debug",
  restBaseUrl: "https://dev-api.vibe-beta.com/v1",
  wsBaseUrl: "wss://dev-wss.vibe-beta.com",
});

export const serverUrls = {
  prodServerUrl,
  betaServerUrl,
  devServerUrl,
};
