import React from "react";
import "./styles.css";

interface CustomLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  contentClassName?: string;
}

const CustomLayout = ({ children, header, footer, contentClassName }: CustomLayoutProps) => {
  const customContentClassName = contentClassName
    ? "custom-content " + contentClassName
    : "custom-content";
  return (
    <div className="custom-wrapper">
      <div className="custom-header-placeholder">{header}</div>
      <div className={customContentClassName}>{children}</div>
      <div className="custom-header-footer">{footer}</div>
    </div>
  );
};

export default CustomLayout;
