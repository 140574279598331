import React from "react";
import constants from "@/constants";
import { getQueryValueByKeys } from "@/utils";
import "./styles.css";

const ApprovalInfoList = () => {
  const { infoQueryKeys } = constants;
  const infoValues = getQueryValueByKeys(constants.infoQueryKeys);
  const itemLength = infoQueryKeys.length;
  const infoKeys = ["Device", "Time", "Account", "IP address"];
  return (
    <div className="info-list-wrapper">
      {infoQueryKeys.map((key, index) => (
        <>
          <div className="info-list-item" key={key}>
            <div className="item-key">{infoKeys[index]}</div>
            <div className="item-value">{infoValues[index] || ""}</div>
          </div>
          {index < itemLength - 1 && <div className="divider" />}
        </>
      ))}
    </div>
  );
};
export default ApprovalInfoList;
